import '@fairlo/ui-kit/dist/esm/index.css';
import '@fontsource-variable/inter';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'normalize.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { StyleSheetManager } from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import App from './components/App';
import GlobalStyle from './components/GlobalStyle';
import loadDevTools from './devtools/load';
import { initializeLogRocket } from './integrations/logRocket';
import { initializeSentry } from './integrations/sentry';
import { queryClient } from './libs/tanstackQuery';
import initializeI18n from './localization/i18n';

initializeLogRocket();
initializeSentry();

const container = document.getElementById('root');
const rootElement = createRoot(container!);

initializeI18n();

loadDevTools(() => {
  rootElement.render(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <StyleSheetManager enableVendorPrefixes>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </StyleSheetManager>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
});
