import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { AnalyticsEvents } from './constants/analytics';
import { segment } from './integrations/segment';
import { DEFAULT_CURRENCY } from './localization/constants';
import { environment } from './utils/env.utils';

type TrackUserOptions = {
  id: string;
  email?: string;
};

export async function trackUser({ id, email }: TrackUserOptions) {
  Sentry.setUser({
    id,
    email
  });
  await segment.identify(id, email ? { email } : undefined);
  LogRocket.identify(id, email ? { email } : undefined);
}

// Indicates that the user has logged out and should no longer be tracked
export const untrackUser = async () => {
  Sentry.setUser(null);
  await segment.reset();
};

export async function trackPageView() {
  await segment.page({
    version: environment.version
  });
}

export type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

export async function trackEvent(name: string, props?: TrackEventProperties) {
  await segment.track(name, props);
  LogRocket.track(name, props);
}

type FinancialEventOptions = Record<string, unknown> & {
  amount: number;
  agreementId: string;
};

export const trackFinancialEvent = async (
  name: string,
  { amount, agreementId, ...restOptions }: FinancialEventOptions
) =>
  trackEvent(name, {
    value: amount,
    revenue: amount, // Needed for reporting to Bing
    currency: DEFAULT_CURRENCY,
    agreementID: agreementId,
    ...restOptions
  });

export const trackAddToCartEvent = async (variant: 'Levla' | 'InitialCredit') =>
  trackFinancialEvent(AnalyticsEvents.ADD_TO_CART, {
    amount: 0,
    agreementId: '0',
    variant
  });

type SEApplicationStep =
  | 'personal-details'
  | 'employment-status'
  | 'payment-protection-insurance'
  | 'finances';
type UKApplicationStep =
  | 'personal-details'
  | 'address'
  | 'income'
  | 'outgoings'
  | 'credit-limit'
  | 'mobile-verification'
  | 'documents'
  | 'marketing-consent';

type SubmitCreditApplicationStepOptions = {
  applicantId: string;
  step: SEApplicationStep | UKApplicationStep;
};
export const trackSubmitCreditApplicationStep = (options: SubmitCreditApplicationStepOptions) =>
  trackEvent(AnalyticsEvents.SUBMIT_CREDIT_APPLICATION_STEP, options);
