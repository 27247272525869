import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import useRetryUntilResolved from '../hooks/useRetryUntilResolved';
import { environment } from '../utils/env.utils';

export const initializeLogRocket = () => {
  if (environment.env !== 'production') {
    return;
  }

  LogRocket.init(environment.logRocketAppId, {
    rootHostname: environment.rootHostName,
    release: environment.version
  });
};

export const useLogRocketSessionInSentry = () => {
  useRetryUntilResolved(() => {
    // LogRocket is only available in production, skip this hook in development
    if (environment.env !== 'production') {
      return true;
    }

    if (!Sentry.withScope || !LogRocket) {
      return false;
    }

    LogRocket.getSessionURL(sessionURL => {
      Sentry.withScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });

    return true;
  });
};
